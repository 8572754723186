@use "sass:map";

// Fonts
$font-fam-dm-mono: "DM Mono", Courier, monospace;
$font-fam-dm-sans: "DM Sans", Arial, Helvetica, sans-serif;
$font-fam-catamaran: "Catamaran", Arial, Helvetica, sans-serif;
$font-fam-barlow: "Barlow", Arial, Helvetica, sans-serif;
$font-fam-poppins: "Poppins", Arial, Helvetica, sans-serif;

// Colors
$colors: (
    "white": #fefefe,
    "light-gray": #e4e4e4,
    "dark-gray": #555555,
    "maroon": #831b3d,
    "light-red": #d81159,
    "dark-red": #8f2d56,
    "green": #6f9947,
    "light-green": #00ca51,
    "upri-green": #0f8944,
    "teal": #218380,
    "sky-blue": #73d2de,
    "yellow": #ffbc42,
);

// Autogenerate color class names
@each $color-name, $color-val in $colors {
    .color-#{$color-name} {
        color: $color-val;
    }

    .bg-color-#{$color-name} {
        background-color: $color-val;
    }
}

// Sizes
$main-margin-small: 2vh;
$main-margin-large: 15vh;
$navbar-height: 5rem;
$footer-min-height: 5vh;
$card-padding: 1rem;

// Preset values
$max-mobile-width: 600px;
$elevate-z1: 0.1rem 0.1rem 0.2rem map.get($colors, "light-gray");