@use "sass:map";
@use '../vars' as vars;

form#concise-search {
    div.search-bar {
        position: relative;
        box-sizing: border-box;

        button.icon {
            position: absolute;
            top: 0;
            left: 0;
            margin: 0;
            padding: 0;
            width: 3rem;
            height: 100%;
            color: map.get(vars.$colors, "dark-gray");
            border-radius: 20px 0 0 20px;

            &:hover {
                background-color: map.get(vars.$colors, "green");
                color: map.get(vars.$colors, "white");
            }
        }

        input[name="q"] {
            background-color: map.get(vars.$colors, "light-gray");
            border-radius: 2rem;
            border: 0;
            width: 100%;
            outline: 0;
            padding-left: 3.25rem;

            &:focus {
                background-color: transparent;
                border: 1px solid map.get(vars.$colors, "green");

                & + button.icon:not(:hover) {
                    color: map.get(vars.$colors, "green");
                }
            }
        }   
    }
}

#search-filters {
    label.filter-set {
        display: inline-block;
        background-color: map.get(vars.$colors, "dark-gray");
        border-radius: 2rem;
        margin: 0.25rem 0;
        padding: 0.25rem 0.5rem;
        color: map.get(vars.$colors, "light-gray");

        & i.material-icons {
            vertical-align: middle;
            margin-right: 0.25rem;
        }

        &:hover {
            background-color: map.get(vars.$colors, "green");
            transition: 0.5s;
        }
    }

    #search-filter-modal {
        .content.loader {
            .upri-loader {
                display: block;
                margin: 0 auto;
            }

            &.hide {
                display: none;
            }
        }
    }
}

@media (prefers-color-scheme: dark) {
    form#concise-search {
        div.search-bar {
            input[name="q"]:not(:focus) {
                color: map.get(vars.$colors, "dark-gray");
            }
        }

        button.filter-set {
            color: map.get(vars.$colors, "dark-gray");
            background-color: map.get(vars.$colors, "light-gray");
        }
    }
}