@use "sass:map";
@use "../vars" as vars;

.upri-loader {
    width: 48px;
    height: 48px;
    background: transparent;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: upri-loader-rotation 1s linear infinite;

    &::after {
        content: '';  
        box-sizing: border-box;
        position: absolute;
        left: 8px;
        top: 8px;
        width: 12px;
        height: 12px;
        color: map.get(vars.$colors, "upri-green");
        background: currentColor;
        border-radius: 50%;
        box-shadow: 20px 2px, 8px 18px;
    }
}

@keyframes upri-loader-rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 
