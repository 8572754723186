@use "sass:map";
@use "../vars" as vars;

.glide {
    &__track {
        width: 90%;
        margin: 0 auto;
    }

    &__arrows {
        position: absolute;
        top: 40%;
        width: 100%;
    }

    &__arrow {
        position: absolute;
        width: 3rem;
        height: 3rem;
        padding: 0;
        border-radius: 5rem;
        background-color: map.get(vars.$colors, "dark-gray");
        color: map.get(vars.$colors, "white");

        &--left {
            left: 0;
        }

        &--right {
            right: 0;
        }
    }

    &__bullets {
        width: 100%;
        height: 1rem;
        text-align: center;
    }

    &__bullet {
        background-color: map.get(vars.$colors, "light-gray");
        width: 1rem;
        height: 1rem;
        padding: 0;

        &:not(:first-child) {
            margin-left: 0.25rem;
        }

        &--active {
            background-color: map.get(vars.$colors, "light-green");
        }
    }
}

@media (prefers-color-scheme: dark) {
    .glide {
        &__arrow {
            background-color: map.get(vars.$colors, "light-gray");
            color: map.get(vars.$colors, "dark-gray");
        }

        &__bullet {
            background-color: map.get(vars.$colors, "light-gray");
    
            &--active {
                background-color: map.get(vars.$colors, "light-green");
            }
        }
    }
}
