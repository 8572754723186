@use "sass:map";
@use '../vars' as vars;

nav#main-nav {
    position: fixed;
    height: vars.$navbar-height;
    padding: 0 4vw;
    box-shadow: 0 3px map.get(vars.$colors, "maroon"),
                0 6px map.get(vars.$colors, "green");

    a.brand {
        .logo-img {
            height: calc(vars.$navbar-height - 2rem);

            &:not(:first-child) {
                margin-left: 0.5rem;
            }
        }

        .logo-text {
            width: 10vw;
            margin-left: 0.5vw;

            @media (max-width: 600px) {
                display: none;
            }
        }

        span {
            color: map.get(vars.$colors, "maroon");
            font-size: 1.5rem;
            margin-left: 1rem;
        }
    }
        
    .menu {
        a {
            font-size: 1.25rem;
            padding: 0.5rem 1.5rem;
            background-color: transparent;
            color: map.get(vars.$colors, "green");
            border-radius: 3rem;

            &:hover {
                background-color: #eee;
            }
        }

        a.active {
            font-weight: bold;
            color: white;
            background-color: map.get(vars.$colors, "green");
        }
    }
}

@media (prefers-color-scheme: dark) {
    nav#main-nav {
        background-color: map.get(vars.$colors, "light-gray");
    }
}
